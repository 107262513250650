<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 v-if="message.title" class="modal-title">
                        {{ message.title }}
                    </h5>
                    <button @click="visible = false" type="button" class="close" data-dismiss="modal"
                            :aria-label="$t('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div v-if="message" class="col-12" style="max-height: 80vh; overflow-y: auto;" v-html="nl2br(message.note)">

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {EventBus, Events} from "@/EventBus";
import Helper from "@/Helper";
import AbstractModal from "@/modals/AbstractModal.vue";

export default {
    name: "NoteMessageModal",
    extends: AbstractModal,
    data() {
        return {
            message: ''
        }
    },
    methods: {
        nl2br: Helper.nl2br,
        onShowModal(message) {
            this.visible = true;
            this.message = message
        },
    },
    mounted() {
        EventBus.$on(Events.SHOW_NOTE_MESSAGE_MODAL, this.onShowModal);
    },
    beforeDestroy() {
        EventBus.$off(Events.SHOW_NOTE_MESSAGE_MODAL, this.onShowModal);
    }
}
</script>

<style lang="less" scoped>

</style>
