const getBaseUrl = () => {

    if (window.location.host === "localhost:8080") {
        return "http://localhost/api"
    }

    if (process.env.NODE_ENV === 'development' || window.location.host === "manual.i.devutg.com") {
        return "https://api.manual.i.devutg.com/api"
    }

    return "https://api.manual.freeutg.com/api";
}

const localityIds4plus2 = [
    41, //Гайсин
    1849, //Карбівка
    1850, //Кисляк
    1851, //Крутогорб
    1852, //Куна
    1853, //Мар'янівка
    1848, //Михайлівка
    1848, //Михайлівка
    550, //Знаменка
    555, //Знаменка 2
    558, //Петрово
    624, //Новый Буг
]

/**
 * НП які доступні юзерам з департаменту Connect
 */
const connectDepartmentLocalities = [
    711,
    821,
    872,
    1266,
    1267,
    1268,
    1269,
    1270,
    1271,
    1272,
    1273,
    1274,
    1276,
    1277,
    1279,
    1280,
    1281,
    1282,
    1283,
    1284,
    1285,
    1286,
    1287,
    1288,
    1289,
    1460,
    1462,
]

export default {
    baseApiUrl: getBaseUrl(),
    localityIds4plus2: localityIds4plus2,
    connectDepartmentLocalities: connectDepartmentLocalities,
}


