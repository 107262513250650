<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ product && product.name }}
                    </h5>
                    <button @click="visible = false" type="button" class="close" data-dismiss="modal"
                            :aria-label="$t('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div v-if="product" class="col-12" style="max-height: 80vh; overflow-y: auto;">
                        <div v-if="product.comment" v-html="nl2br(product.comment)"
                             class="alert alert-danger"></div>
                        <div class="d-flex justify-content-start align-items-start">
                            <img class="product-image mr-4 mb-4"
                                 v-if="product.images_links.length"
                                 :src="product.images_links[0]">
                            <div>
                                <h4>{{ product.name }}</h4>
                                <div class="mb-2">
                                    {{ $t('Цена') }}: <strong class="text-primary font-weight-bold">{{ product.price }}
                                    {{ $t('грн') }}.</strong>
                                </div>
                                <div class="mb-2">{{ $t('Bitrix ID') }}: <strong>{{ product.bitrix_id }}</strong></div>
                                <div class="mb-2">{{ $t('Добавлен') }}: <strong>{{ product.created_at }}</strong></div>
                                <div v-if="is('administrator')" class="mb-2">
                                    <router-link class="text-danger" :to="`/product/${product.id}`">
                                        {{ $t('Редактировать') }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <p v-if="product.description" v-html="nl2br(product.description)"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {EventBus, Events} from "@/EventBus";
import Helper from "@/Helper";
import AbstractModal from "@/modals/AbstractModal.vue";

export default {
    name: "ProductModal",
    extends: AbstractModal,
    data() {
        return {
            product: null
        }
    },
    methods: {
        nl2br: Helper.nl2br,
        onShowModal(product) {
            this.visible = true;
            this.product = product
        },
    },
    mounted() {
        EventBus.$on(Events.SHOW_PRODUCT_MODAL, this.onShowModal);
    },
    beforeDestroy() {
        EventBus.$off(Events.SHOW_PRODUCT_MODAL, this.onShowModal);
    }
}
</script>

<style lang="less" scoped>

.product-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
}


</style>
