<template>
    <ul class="app-nav-tabs nav nav-tabs mb-4 flex-column flex-md-row">
        <li @click.prevent="$emit('change', null)"
            :class="{ active : null === currentPageId }"
            class="nav-item">
            <a class="nav-link" href="#">
                {{ $t("Все") }}
                <small v-if="getFacebookCountUnreadCommentsSum"
                       class="badge badge-danger badge-pill position-relative"
                       style="top: -5px">
                    {{ getFacebookCountUnreadCommentsSum }}
                </small>
            </a>
        </li>
        <li v-for="(page, index) in activePages"
            @click.prevent="$emit('change', page.page_id)"
            :key="index"
            :class="{ active : page.page_id === currentPageId, 'ml-5': isFirstInstagramTab(index) }"
            class="nav-item">
            <a class="nav-link" href="#">
                <img :src="page.picture_url || `https://graph.facebook.com/${page.page_id}/picture`"
                     style="border-radius: 4px;"
                     width="18" height="18">
                {{ getPageName(page) }}
                <small v-if="page.count_unread" class="badge badge-danger badge-pill position-relative"
                       style="top: -5px">
                    {{ page.count_unread }}
                </small>
            </a>
        </li>
        <li class="ml-auto">
            <slot></slot>
        </li>
    </ul>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {camelCase, startCase} from "lodash";

export default {
    name: "FacebookCommentsPagesTabs",
    props: {
        pages: Array,
        currentPageId: String,
    },
    methods: {
        isFirstInstagramTab(index) {
            return (
                this.activePages?.[index]?.type === "instagram"
                && this.activePages?.[index - 1]?.type === "facebook"
            );
        },
        getPageName(page) {
            if(page.type === "facebook") {
                return page.name;
            }

            return startCase(camelCase(page.name));
        }
    },
    computed: {
        ...mapState({
            facebookCountUnreadComments: state => state.facebookCountUnreadComments,
        }),
        ...mapGetters({
            getFacebookCountUnreadCommentsSum: "getFacebookCountUnreadCommentsSum",
        }),
        activePages() {
            return this.pages
                .filter(p => p.status === "active")
                .map(page => {
                    page.count_unread = this.facebookCountUnreadComments[page.page_id] || null;
                    return page
                }).sort((a, b) => {
                    return a.type.localeCompare(b.type);
                });
        }
    }
}
</script>

<style lang="less">

</style>
