<template>
    <emoji-picker ref="picker" @emoji="append">
        <button
            class="emoji-invoker"
            slot="emoji-invoker"
            slot-scope="{ events: { click: clickEvent } }"
            @click.stop="clickEvent"
        >
            <i class="far fa-smile fa-lg text-secondary"></i>
        </button>
        <div slot="emoji-picker" slot-scope="{ emojis, insert, display }">
            <div class="emoji-picker" :style="{ top: display.y + 'px', left: display.x + 'px' }">
                <div>
                    <div v-for="(emojiGroup, category) in emojis" :key="category">
                        <h5>{{ category }}</h5>
                        <div class="emojis">
                <span
                    v-for="(emoji, emojiName) in emojiGroup"
                    :key="emojiName"
                    @click="insert(emoji)"
                    :title="emojiName"
                >{{ emoji }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </emoji-picker>
</template>
<script>

import {EmojiPicker} from 'vue-emoji-picker'

export default {
    name: "EmojiPickerComponent",
    components: {
        EmojiPicker
    },
    methods: {
        append(emoji) {
            this.$emit('select', emoji);
        },
    }
}
</script>

<style scoped lang="less">

.emoji-invoker {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
    padding: 0;
    background: transparent;
    border: 0;
}

.emoji-invoker:hover {
    transform: scale(1.1);
}

.emoji-picker {
    position: absolute;
    z-index: 1;
    border: 1px solid #ccc;
    width: 350px;
    height: 200px;
    overflow: scroll;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    background: #fff;
    box-shadow: 1px 1px 8px #c7dbe6;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
}

.emoji-picker h5 {
    margin-bottom: 0;
    color: #b1b1b1;
    text-transform: uppercase;
    font-size: .8rem;
    cursor: default;
}

.emoji-picker .emojis {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.emoji-picker .emojis:after {
    content: "";
    flex: auto;
}

.emoji-picker .emojis span {
    padding: 0.6rem;
    cursor: pointer;
    border-radius: 5px;
    scale: 1.5;
}

.emoji-picker .emojis span:hover {
    background: #ececec;
    cursor: pointer;
}
</style>
