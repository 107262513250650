<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ $t('Комментарии поста') }}
                    </h5>
                    <button @click="visible = false" type="button" class="close" data-dismiss="modal"
                            :aria-label="$t('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div v-if="isLoading" class="d-flex justify-content-center align-items-center py-5">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div v-else>
                        <CommentItem v-for="comment in comments"
                                                  :key="comment.id"
                                                  visible-children-comments
                                                  hide-dialog-button
                                                  class="mb-3"
                                                  :comment="comment"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {EventBus, Events} from "@/EventBus";
import moment from "moment-mini";
import FacebookCommentsService from "@/services/FacebookCommentsService";
import CommentItem from "@/components/facebook_commentns/CommentItem.vue";
import AbstractModal from "@/modals/AbstractModal.vue";

const FacebookComments = new FacebookCommentsService();

export default {
    name: "FacebookPostCommentsModal",
    extends: AbstractModal,
    components: {
        CommentItem
    },
    data() {
        return {
            comments: [],
            comment: null,
            isLoading: false,
        }
    },
    filters: {
        formatDate(date) {
            return moment(date).format("YYYY-MM-DD HH:mm:ss");
        },
    },
    methods: {
        async loadPostComments() {
            this.isLoading = true;
            const {code, body} = await FacebookComments.fetchPostComments(this.comment.post_id);
            this.isLoading = false;

            if (code === 200) {
                this.comments = body;
            } else {
                this.$toast.error(this.$t("Не удалось получить комментарии поста"));
            }
        },
        onShowModal(comment) {
            this.visible = true;
            this.comment = comment;
            this.loadPostComments();
        },
    },
    mounted() {
        EventBus.$on(Events.FB_POST_COMMENTS_MODAL_OPEN, this.onShowModal);
    },
    beforeDestroy() {
        EventBus.$off(Events.FB_POST_COMMENTS_MODAL_OPEN, this.onShowModal);
    }
}
</script>

<style lang="less" scoped>
.comment-wrapper {
    background: #def1ff;
    border: 1px solid #9cdaff;
    padding: 10px;
    border-radius: 10px;
}
</style>
