<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ $t('Ответить на комментарий') }}
                    </h5>
                    <button @click="visible = false" type="button" class="close" data-dismiss="modal"
                            :aria-label="$t('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="font-italic text-muted mb-2">
                        <strong>{{ comment?.from_name }}</strong>
                        <small class="pl-3">{{ comment?.created_time | formatDate }}</small>
                        <a class="pl-3" v-if="comment?.post_permalink_url" :href="comment?.post_permalink_url"
                           target="_blank">
                            {{ $t("Перейти к посту") }}
                            <i class="fas fa-external-link-alt"></i>
                        </a>
                    </div>
                    <div class="comment-wrapper mb-4">
                        {{ comment?.message }}
                        <img v-if="comment?.photo" :src="comment?.photo" style="max-width: 100px; max-height: 100px;"
                             alt="img">
                    </div>
                    <CommentActions v-if="comment"
                                    :comment="comment"
                                    class="btn-block mb-4"
                                    hide-reply-button/>
                    <div class="position-relative">
                        <textarea v-model="replyMessage"
                                  ref="replyMessage"
                                  class="form-control"
                                  :placeholder="$t('Ответ на комментарий')"
                                  rows="10"></textarea>
                        <EmojiPickerComponent @select="insertEmoji"/>
                    </div>

                </div>
                <div class="modal-footer">
                    <button @click="sendReply" :disabled="isLoading || !replyMessage.length"
                            class="btn btn-block btn-primary">
                        <div v-if="isLoading" class="spinner-border spinner-border-sm text-white" role="status">
                            <span class="sr-only">{{ $t('Работаем...') }}</span>
                        </div>
                        <span v-else>{{ $t('Опубликовать комментарий') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {EventBus, Events} from "@/EventBus";
import moment from "moment-mini";
import FacebookCommentsService from "@/services/FacebookCommentsService";
import AbstractModal from "@/modals/AbstractModal.vue";
import CommentActions from "@/components/facebook_commentns/CommentActoins.vue";
import EmojiPickerComponent from "@/components/facebook_commentns/EmojiPickerComponent.vue";
import InstagramCommentsService from "@/services/InstagramCommentsService";

const FacebookComments = new FacebookCommentsService();
const InstagramComments = new InstagramCommentsService();

export default {
    name: "FacebookReplyToCommentModal",
    components: {
        EmojiPickerComponent,
        CommentActions,
        EmojiPicker,
    },
    extends: AbstractModal,
    data() {
        return {
            comment: null,
            replyMessage: "",
            isLoading: false,
        }
    },
    filters: {
        formatDate(date) {
            return moment(date).format("YYYY-MM-DD HH:mm:ss");
        },
    },
    methods: {
        insertEmoji(emoji) {
            const textarea = this.$refs.replyMessage;
            if (!textarea) return;

            const start = textarea.selectionStart;
            const end = textarea.selectionEnd;
            const text = textarea.value;

            this.replyMessage = text.substring(0, start) + emoji + text.substring(end);

            const newCursorPos = start + emoji.length;
            textarea.setSelectionRange(newCursorPos, newCursorPos);

            textarea.focus();
            textarea.click();
        },
        async sendReply() {
            const service = this.comment.object === "instagram"
                ? InstagramComments
                : FacebookComments;

            this.isLoading = true;
            const {code, body} = await service.replyToComment(this.comment.id, this.replyMessage);
            this.isLoading = false;

            if (code === 200) {
                this.$toast.success(this.$t('Комментарий опубликован'));
                this.$emit("replied");
                this.closeModal();
            } else {
                this.$toast.error(this.$t("Не удалось опубликовать ответ, возможно комментарий уже удален"));
                console.warn(body.message || body.error.message);
            }
        },
        onShowModal(comment) {
            this.visible = true;
            this.comment = comment;
        },
    },
    mounted() {
        EventBus.$on(Events.FB_REPLY_TO_COMMENT_MODAL_OPEN, this.onShowModal);
    },
    beforeDestroy() {
        EventBus.$off(Events.FB_REPLY_TO_COMMENT_MODAL_OPEN, this.onShowModal);
    }
}
</script>

<style lang="less" scoped>
.comment-wrapper {
    background: #def1ff;
    border: 1px solid #9cdaff;
    padding: 10px;
    border-radius: 10px;
}

</style>
