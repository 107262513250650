<template>
    <div class="comment-wrapper w-100 mb-3"
         :class="{ 'own-comment' : isOwnComment, 'deleted-comment': isDeleted }">
        <div class="font-weight-bold font-italic text-muted mb-2 d-flex align-items-center justify-content-between">
            <div>
                {{ fromName || $t("Пользователь") }} &#x2022;
                <span class="font-weight-normal" v-if="hasParentComment">
                        {{ $t("ответил(а) на комментарий") }}
                        <a @click.prevent="showParentComment" href="#">#{{ comment.id }}</a>
                    </span>
                <span v-else class="font-weight-normal">{{ $t("написал(а)") }}</span>
            </div>
            <span class="font-weight-normal text-info">{{ formatDate(createdTime) }}</span>
        </div>
        <hr class="mb-2">
        <div>{{ message }}</div>
        <img v-if="photo" :src="photo" style="max-width: 100px; max-height: 100px;" alt="img">
        <div class="children-comment pl-3">
            <a href="#" v-if="comment.children?.length"
               style="margin-left: -15px;"
               @click.prevent="visibleChildrenComments__ = !visibleChildrenComments__"
               class="font-italic badge badge-secondary badge-pill mb-2 px-3 d-inline-block">
                {{ visibleChildrenComments__ ? $t("свернуть") : $t("развернуть ответ...") }}
            </a>
            <component :is="$options.name"
                       v-if="comment.children?.length && visibleChildrenComments__"
                       v-for="child in comment.children"
                       :visibleChildrenComments="visibleChildrenComments"
                       :hideDialogButton="hideDialogButton"
                       :key="child.id"
                       :comment="child"/>
        </div>
        <hr class="mt-2">
        <CommentActions :comment="comment"/>
    </div>
</template>

<script>

import TimeAgo from "javascript-time-ago";
import uk from 'javascript-time-ago/locale/uk'
import en from 'javascript-time-ago/locale/en'
import bg from 'javascript-time-ago/locale/bg'
import ru from 'javascript-time-ago/locale/ru'
import {EventBus, Events} from "@/EventBus";
import {mapGetters} from "vuex";
import CommentActions from "@/components/facebook_commentns/CommentActoins.vue";

export default {
    name: "CommentItem",
    components: {
        CommentActions
    },
    props: {
        comment: {
            type: Object,
            required: true
        },
        visibleChildrenComments: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideDialogButton: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            visibleChildrenComments__: false
        }
    },
    methods: {
        formatDate(date) {
            const timeAgo = new TimeAgo(this.$i18n.locale);
            return timeAgo.format(new Date(date));
        },
        showParentComment() {
            EventBus.$emit(Events.FB_PARENT_COMMENT_MODAL_OPEN, this.comment);
        },
    },
    computed: {
        ...mapGetters({
            getFacebookRemovalComments: "getFacebookRemovalComments",
        }),
        message() {
            return this.comment?.message;
        },
        photo() {
            return this.comment?.photo;
        },
        isOwnComment() {
            return this.comment.entry_id === this.comment.from_id;
        },
        fromName() {
            return this.comment?.from_name;
        },
        createdTime() {
            return this.comment?.created_time;
        },
        hasParentComment() {
            // todo - колись треба переробити цей механізм
            // якщо коментар залишено не до посту, а до фото, то parent_id може НЕ дорівнювати post_id,
            // що дає хибний висновок, що у коментаря є батьківський коментар.
            // для коментарів під фото - хардкодимо відсутність parent комментарів
            if (this.isCommentOnPhoto) {
                return false;
            }

            return this.comment.parent_id !== this.comment.post_id;
        },
        isCommentOnPhoto() {
            return /photo.php/.test(this.comment.post_permalink_url);
        },
        isDeleted() {
            return this.getFacebookRemovalComments.find(item => item.comment_id === this.comment.id);
        },
    },
    watch: {
        visibleChildrenComments: {
            immediate: true,
            handler(val) {
                this.visibleChildrenComments__ = val;
            }
        },
    },
    created() {
        TimeAgo.addLocale(uk);
        TimeAgo.addLocale(ru);
        TimeAgo.addLocale(en);
        TimeAgo.addLocale(bg);
    },
}
</script>

<style scoped lang="less">
.comment-wrapper {
    background: #def1ff;
    border: 1px solid #2ab0ff;
    padding: 10px;
    border-radius: 10px;
}

.own-comment {
    background: darken(#def1ff, 5);
    border: 1px solid darken(#2ab0ff, 5);
}

.deleted-comment {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    & > * {
        opacity: .3;
    }

    &:after {
        position: absolute;
        content: "Comment deleted";
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        background-color: fade(#ff0000, 30);
        color: red;
        font-size: 28px;
        font-weight: bold;
    }
}
</style>
