<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">...</h5>
                    <button @click="visible = false" type="button" class="close" data-dismiss="modal"
                            :aria-label="$t('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body"></div>
                <div class="modal-footer"></div>
            </div>
        </div>
    </div>
</template>

<script>

import {EventBus, Events} from "@/EventBus";

export default {
    name: "AbstractModal",
    data() {
        return {
            visible: false,
        }
    },
    methods: {
        closeOtherModals() {
            $('.modal').modal('hide');
        },
        closeModal() {
            Object.assign(this.$data, this.$options.data.apply(this));
            $(this.$refs.modal).modal("hide");
        },
        showModal() {
            $(this.$refs.modal).modal("show");
        },
        onHideBsModal() {
            this.visible = false;
        },
        onShowModal() {
            this.visible = true;
        },
    },
    watch: {
        visible(val) {
            if (val) {
                this.closeOtherModals();
                this.showModal();
            } else {
                this.closeModal();
            }
        }
    },
    mounted() {
        EventBus.$on(Events.CLOSE_ALL_MODALS, this.closeOtherModals);
        $(this.$el).on('hide.bs.modal', this.onHideBsModal);
    },
    beforeDestroy() {
        EventBus.$off(Events.CLOSE_ALL_MODALS, this.closeOtherModals);
        $(this.$el).off('hide.bs.modal', this.onHideBsModal);
    }
}
</script>

<style scoped>

</style>
