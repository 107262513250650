<template>
    <div class="card mt-4 overflow-hidden">
        <div class="card-body p-3 d-flex align-items-center justify-content-between">
            <div class="mr-4">
                <img :src="page.picture_url" width="77" height="77" class="rounded-circle">

            </div>
            <div class="flex-fill">
                <h6 class="font-weight-bold mb-0">
                    {{ name }}
                    <small class="badge badge-warning position-relative"
                           style="top: -5px">
                        {{ page.type }}
                    </small>
                    <small class="badge badge-success position-relative ml-2"
                           style="top: -5px"
                           :class="{ 'badge-danger' : status === 'inactive' }">
                        {{ status }}
                    </small>
                </h6>
                <a class="d-block" :href="`https://www.instagram.com/${page.name}`" target="_blank">
                    https://www.instagram.com/{{ page.name }}
                </a>
            </div>
            <div>
                <toggle-button class="mr-4"
                               @change="toggle"
                               :font-size="10"
                               sync
                               :value="status === 'active'"
                               :width="50" :height="30"/>
            </div>
        </div>
    </div>
</template>

<script>

import FacebookIntegrationService from "@/services/FacebookIntegrationService";
import {ToggleButton} from 'vue-js-toggle-button';

const FbService = new FacebookIntegrationService();

export default {
    name: 'InstagramPageItem',
    components: {
        ToggleButton
    },
    props: {
        page: {
            type: Object,
            required: true,
        }
    },
    methods: {
        async toggle() {
            this.$preloader.show();
            const status = this.status === 'active' ? 'inactive' : 'active';
            const {code, body} = await FbService.setPageStatus(this.page.id, status);
            this.$preloader.hide();

            if (code === 200) {
                this.$emit('change');
            } else {
                this.$toast.error(body.message);
            }
        },
    },
    computed: {
        pageId() {
            return this.page?.page_id;
        },
        name() {
            return this.page?.name;
        },
        category() {
            return this.page?.category;
        },
        status() {
            return this.page?.status;
        },
    }
}
</script>
