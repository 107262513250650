<template>
    <div class="w-100">
        <div v-if="pageData" class="d-flex justify-content-start align-items-center">
            <div class="mr-2">
                <img :src="pageData.picture_url || `https://graph.facebook.com/${page_id}/picture`"
                     style="border-radius: 6px;"
                     width="30" height="30">
            </div>
            <small v-if="pageData.type === 'facebook'">
                <a :href="`https://www.facebook.com/${page_id}`" target="_blank" class="font-weight-bold text-nowrap">
                    {{ pageName }}
                </a>
            </small>
            <small v-if="pageData.type === 'instagram'">
                <a :href="`https://www.instagram.com/${pageData.name}`" target="_blank" class="font-weight-bold text-nowrap">
                    {{ pageName }}
                </a>
            </small>
        </div>
        <div v-else>
            n/a
        </div>
    </div>
</template>

<script>
import {camelCase, startCase} from "lodash";

export default {
    name: "CommentsTablePageItem",
    props: {
        page_id: {
            type: String,
            required: true
        },
        pages: {
            type: Array,
            required: true
        }
    },
    computed: {
        pageData() {
            return this.pages.find(page => page.page_id === this.page_id);
        },
        pageName() {
            if(this.pageData.type === "facebook") {
                return this.pageData.name;
            }

            return startCase(camelCase(this.pageData.name));
        }
    },
}
</script>

<style scoped lang="less">

</style>
