import AbstractService from "@/services/AbstractService";

export default class FacebookIntegrationService extends AbstractService {

    constructor() {
        super();
    }

    getUserExchangeToken(token) {
        return this.axios.get(`/fb/user-exchange-token?access_token=${token}`);
    }

    getUserPages() {
        return this.axios.get("/fb/pages");
    }

    setUserPages(pages) {
        return this.axios.post("/fb/pages", {pages});
    }

    setPageStatus(pageId, status) {
        return this.axios.put(`/fb/pages/${pageId}`, {status});
    }

    disconnectPageFromApp(pageId) {
        return this.axios.delete(`/fb/pages/${pageId}`);
    }

    replyToComment(commentId, message) {
        return this.axios.post(`/fb/comments/${commentId}/reply`, {message});
    }
}
