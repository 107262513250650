import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Auth from "@/views/Auth";
import Locality from "@/views/Locality";
import Register from "@/views/Register";
import Users from "@/views/Users";
import Departments from "@/views/Departments";
import Comments from "@/views/Comments";
import Opponents from "@/views/Opponents";
import LocalityNotes from "@/views/LocalityNotes";
import UserRoles from "@/views/UserRoles";
import TariffNotes from "@/views/TariffNotes";
import Profile from "@/views/Profile";
import AuthLayout from "@/layoyts/AuthLayout";
import Products from "@/views/Products";
import Product from "@/views/Product";
import Scripts from "@/views/Scripts";
import Script from "@/views/Script";
import SmsMessage from "@/views/SmsMessage";
import SmsSample from "@/views/SmsSample";
import Settings from "@/views/Settings";
import Export from "@/views/Export.vue";
import Activities from "@/views/Activities.vue";
import FacebookComments from "@/views/FacebookComments.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/profile",
        name: "Profile",
        component: Profile,
    },
    {
        path: "/auth",
        name: "Auth",
        component: Auth,
        meta: {
            layout: AuthLayout,
        },
    },
    {
        path: "/register",
        name: "Register",
        component: Register,
        meta: {
            layout: AuthLayout,
        },
    },
    {
        path: "/users",
        name: "Users",
        component: Users,
    },
    {
        path: "/user_roles",
        name: "UserRoles",
        component: UserRoles,
    },
    {
        path: "/departments",
        name: "Departments",
        component: Departments,
    },
    {
        path: "/comments",
        name: "Comments",
        component: Comments,
    },
    {
        path: "/locality_notes",
        name: "LocalityNotes",
        component: LocalityNotes,
    },
    {
        path: "/tariff_notes",
        name: "TariffNotes",
        component: TariffNotes,
    },
    {
        path: "/products",
        name: "Products",
        component: Products,
    },
    {
        path: "/product/:product_id(\\d+)?",
        name: "Product",
        props: true,
        component: Product,
    },
    {
        path: "/scripts",
        name: "Scripts",
        component: Scripts,
    },
    {
        path: "/script/:script_id(\\d+)?",
        name: "Script",
        props: true,
        component: Script,
    },
    {
        path: "/sms_message/:sms_id(\\d+)?",
        name: "SmsMessage",
        props: true,
        component: SmsMessage,
    },
    {
        path: "/sms_sample",
        name: "SmsSample",
        props: true,
        component: SmsSample,
    },
    {
        path: "/opponents",
        name: "Opponents",
        component: Opponents,
    },
    {
        path: "/facebook_comments",
        name: "FacebookComments",
        component: FacebookComments,
    },
    {
        path: "/settings",
        name: "Settings",
        component: Settings,
    },
    {
        path: "/export",
        name: "Export",
        component: Export,
    },
    {
        path: "/locality/:locality_id(\\d+)",
        name: "Locality",
        component: Locality,
        props: true,
    },
    {
        path: "/activities",
        name: "Activities",
        component: Activities,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
