import AbstractService from "@/services/AbstractService";

export default class InstagramCommentsService extends AbstractService {

    constructor() {
        super();
    }

    getPostPermalink(commentId) {
        return this.axios.get(`/ig/comments/${commentId}/permalink`);
    }

    replyToComment(commentId, message) {
        return this.axios.post(`/ig/comments/${commentId}/reply`, {message});
    }
}
