<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered position-absolute w-100" style="right: 10px">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ script && script.name }}
                    </h5>
                    <button @click="visible = false" type="button" class="close" data-dismiss="modal"
                            :aria-label="$t('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <ScriptViewer v-if="script && script.data" :data="script.data"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {EventBus, Events} from "@/EventBus";
import Helper from "@/Helper";
import ScriptViewer from "@/components/ScriptViewer.vue";
import {cloneDeep} from "lodash";
import AbstractModal from "@/modals/AbstractModal.vue";

export default {
    name: "ScriptModal",
    extends: AbstractModal,
    components: {
        ScriptViewer
    },
    data() {
        return {
            script: null
        }
    },
    methods: {
        nl2br: Helper.nl2br,
        onShowModal(script) {
            this.visible = true;
            const s = cloneDeep(script);
            s.data = JSON.parse(s.data);
            this.script = s;
        },
    },
    mounted() {
        EventBus.$on(Events.SHOW_SCRIPT_MODAL, this.onShowModal);
    },
    beforeDestroy() {
        EventBus.$off(Events.SHOW_SCRIPT_MODAL, this.onShowModal);
    },
}
</script>

<style lang="less" scoped>

.product-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
}


</style>
