import AbstractService from "@/services/AbstractService";

export default class FacebookCommentsService extends AbstractService {

    constructor() {
        super();
    }

    fetchComments({limit, offset, sort_by, sort_order, page_id, hide_own_comments, search}) {
        return this.axios.get('/fb/comments', {
            params: {limit, offset, sort_by, sort_order, page_id, hide_own_comments, search},
        });
    }

    fetchPostComments(fb_post_id) {
        return this.axios.get(`/fb/posts/${fb_post_id}/comments`);
    }

    getParentComment(commentId) {
        return this.axios.get(`/fb/comments/${commentId}/parent`);
    }

    deleteComment(commentId) {
        return this.axios.delete(`/fb/comments/${commentId}`);
    }

    deleteCommentLocal(commentId) {
        return this.axios.delete(`/fb/comments/${commentId}/local`);
    }

    replyToComment(commentId, message) {
        return this.axios.post(`/fb/comments/${commentId}/reply`, {message});
    }

    markCommentAsRead(commentId) {
        return this.axios.patch(`/fb/comments/${commentId}`);
    }

    countUnreadMessages() {
        return this.axios.get(`/fb/comments/count_unread`);
    }
}
