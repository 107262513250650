<template>
    <div class="row">
        <div class="col-12 col-xl-6">
            <label class="font-weight-bold mb-3">{{ $t('Разрешить регистрацию пользователей') }}</label>
            <div class="d-flex justify-content-start align-items-center">
                <toggle-button class="mr-4"
                               @change="toggle"
                               :disabled="loading"
                               :font-size="10"
                               sync
                               :value="allowUserRegister"
                               :width="50" :height="30"/>
                <div class="font-weight-bold text-muted pl-3 pb-2">
                    {{ allowUserRegister ? $t('Регистрация разрешена') : $t('Регистрация запрещена') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {ToggleButton} from 'vue-js-toggle-button';
import OptionService from "@/services/OptionService";
import {ALLOW_USER_REGISTER} from "@/BackendOptionsConstants";

const Option = new OptionService()

export default {
    name: "SettingsAllowUsersRegister",
    components: {
        ToggleButton
    },
    data() {
        return {
            loading: false,
            allowUserRegister: false
        }
    },
    methods: {
        async toggle() {
            this.loading = true;
            const {code} = await Option.set({
                option_key: ALLOW_USER_REGISTER,
                option_value: !this.allowUserRegister,
            });
            this.loading = false;

            if (code === 200) {
                this.allowUserRegister = !this.allowUserRegister;
                this.$toast.success(this.$tc("Настройка изменена"));
            } else {
                this.$toast.error(this.$tc("Ошибка"));
            }
        }
    },
    async mounted() {
        this.loading = true;
        const {body} = await Option.fetch();
        this.loading = false;

        this.allowUserRegister = body.find(item => {
            return item.option_key === ALLOW_USER_REGISTER;
        })?.option_value || false;
    }
}
</script>

<style scoped>

</style>
