<template>
    <div class="row">
        <div class="col-12 col-xl-8">
            <label class="font-weight-bold mb-3">{{ $t("Интеграция Facebook") }}</label>
            <div class="card">
                <div class="card-body">
                    <div v-if="isLoading">
                        Loading...
                    </div>
                    <div v-else>
                        <div class="d-flex justify-content-between align-items-center">
                            <button @click="connect" class="btn btn-primary flex-fill">
                                {{ pages.length ? $t("Переподключить") : $t("Подключить") }}
                            </button>
                            <button v-if="pages.length" @click="disconnect" class="btn btn-danger ml-4">
                                {{ $t("Отключить") }}
                            </button>
                        </div>
                        <component :is="getComponent(page)"
                                   v-for="page in pages"
                                   @change="loadSavedPages"
                                   :page="page"
                                   :key="page.id"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FacebookManager from "@/classess/FacebookManager";
import FacebookIntegrationService from "@/services/FacebookIntegrationService";
import FacebookPageItem from "@/components/settings/FacebookPageItem.vue";
import InstagramPageItem from "@/components/settings/InstagramPageItem.vue";

const FbManager = new FacebookManager();
const FbService = new FacebookIntegrationService();

export default {
    name: 'FacebookPagesSettings',
    components: {
        FacebookPageItem,
        InstagramPageItem
    },
    data() {
        return {
            pages: [],
            isLoading: true,
        }
    },
    methods: {
        getComponent(page) {
            if (page.type === "facebook") {
                return FacebookPageItem;
            } else if (page.type === "instagram") {
                return InstagramPageItem;
            } else {
                console.error("Invalid component type");
            }
        },
        async loadSavedPages() {
            this.isLoading = true;
            const {code, body} = await FbService.getUserPages();
            this.isLoading = false;

            if (code === 200) {
                this.pages = body.sort((a, b) => a.type.localeCompare(b.type));
            } else {
                this.$toast.error(body.message);
            }
        },
        async setPages(pages) {
            const {code, body} = await FbService.setUserPages(pages)

            if (code !== 200) {
                this.$toast.error(body.message);
            }
        },
        async disconnectPageFromApp(page) {
            const {code, body} = await FbService.disconnectPageFromApp(page.id);

            if (code !== 200) {
                this.$toast.error(body.message);
            }
        },
        async disconnect() {
            if (!confirm(this.$t("Серьезно?"))) {
                return;
            }

            this.$preloader.show();

            try {
                for (const page of this.pages) {
                    await this.disconnectPageFromApp(page);
                }

                await this.setPages([]);
                await this.loadSavedPages();

                this.$toast.success(this.$t("Страницы отключены"));
            } catch (err) {
                this.$toast.error(err.message);
            } finally {
                this.$preloader.hide();
            }
        },
        async connect() {
            this.$preloader.show();

            try {
                const userToken = await FbManager.login();
                const userExchangeToken = await FbManager.getUserExchangeToken(userToken);
                const selectedPages = await FbManager.getSelectedPages(userExchangeToken);

                for (const page of this.pages) {
                    if (page.type === "facebook") {
                        await this.disconnectPageFromApp(page);
                    }
                }

                for (const page of selectedPages) {
                    if (page.type === "facebook") {
                        await FbManager.connectPageToApp(page);
                    }
                }

                await this.setPages(selectedPages);
                await this.loadSavedPages();

                this.$toast.success(this.$t("Страницы подключены"));
            } catch (err) {
                this.$toast.error(err.message);
            } finally {
                this.$preloader.hide();
            }
        }
    },
    async mounted() {
        this.$preloader.show();
        await this.loadSavedPages();
        this.$preloader.hide();
    }
}
</script>
