<template>
    <div class="btn-group">
        <button v-if="!hideReplyButton" @click="makeReply" class="btn btn-outline-primary btn-sm">
            <i class="fas fa-reply"></i>
            {{ $t("Ответить") }}
        </button>
        <button v-if="isVisibleDialogButton" @click="showDialog"
                class="btn btn-outline-primary btn-sm">
            <i class="fas fa-comments"></i>
            {{ $t("Все комментарии поста") }}
        </button>
        <a v-if="postUrl" :href="postUrl" target="_blank" class="btn btn-outline-primary btn-sm">
            <i class="fas fa-external-link-alt"></i>
            {{ $t("Перейти к посту") }}
        </a>
        <button v-else-if="isInstagramPost" @click="toInstagramPost" class="btn btn-outline-primary btn-sm">
            <i class="fas fa-external-link-alt"></i>
            {{ $t("Перейти к посту") }}
        </button>
        <button @click="removeComment" class="btn btn-outline-danger btn-sm">
            <i class="fas fa-trash"></i>
            {{ $t("Удалить") }}
        </button>
    </div>
</template>

<script>

import {EventBus, Events} from "@/EventBus";
import FacebookCommentsService from "@/services/FacebookCommentsService";
import {mapGetters} from "vuex";
import {mutationTypes} from "@/store";

const FacebookComments = new FacebookCommentsService();

export default {
    name: "CommentActions",
    props: {
        comment: {
            type: Object,
            required: true
        },
        hideReplyButton: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods: {
        toInstagramPost() {
            EventBus.$emit(Events.IG_POST_PERMALINK_MODAL_OPEN, this.comment);
        },
        makeReply() {
            EventBus.$emit(Events.FB_REPLY_TO_COMMENT_MODAL_OPEN, this.comment);
        },
        showDialog() {
            EventBus.$emit(Events.FB_POST_COMMENTS_MODAL_OPEN, this.comment);
        },
        async removeComment() {
            if (!confirm(this.$t("Удалить комментарий? Так же будут удалены все дочерние комментарии."))) {
                return;
            }

            this.$preloader.show();
            const {code, body} = await FacebookComments.deleteComment(this.comment.id);
            this.$preloader.hide();

            EventBus.$emit(Events.CLOSE_ALL_MODALS);

            if (code === 200) {
                // TODO - треба буде додати на бек роут для отримання дочірніх коментарів, щоб помічати весь ланцюжок дочірніх видаляємих коментарів
                this.markCommentAsDeleted(this.comment);
                this.deleteLocalComment(this.comment);
                this.$toast.success(this.$t("Комментарий добавлен в очередь на удаление"));
            } else {
                this.$toast.error(this.$t("Не удалось удалить комментарий"));
                console.warn(body?.message || body?.error?.message);
            }
        },
        deleteLocalComment(comment) {
            if (comment.object === "instagram") {
                FacebookComments.deleteCommentLocal(this.comment.id).catch(err => console.error(err));
            }
        },
        markCommentAsDeleted(comment) {
            const list = [...this.getFacebookRemovalComments];

            list.push({
                comment_id: comment.id,
                expiration: Date.now() + (600 * 1000),
            })

            this.$store.commit(mutationTypes.SET_FACEBOOK_REMOVAL_COMMENTS, list);
        }
    },
    computed: {
        ...mapGetters({
            getFacebookRemovalComments: "getFacebookRemovalComments",
        }),
        message() {
            return this.comment?.message;
        },
        photo() {
            return this.comment?.photo;
        },
        postUrl() {
            return this.comment?.post_permalink_url || null;
        },
        isCommentOnPhoto() {
            return /photo.php/.test(this.comment.post_permalink_url);
        },
        isVisibleDialogButton() {
            return this.isCommentOnPhoto ? false : !this.hideDialogButton;

        },
        isInstagramPost() {
            return this.comment.object === "instagram";
        }
    },
}
</script>

<style scoped lang="less">

</style>
