import Vue from "vue";

export const EventBus = new Vue();
export const Events = {
    SHOW_PRELOADER: "SHOW_PRELOADER",
    HIDE_PRELOADER: "HIDE_PRELOADER",
    CREATE_USER_MODAL_OPEN: "CREATE_USER_MODAL_OPEN",
    CREATE_USER_ROLE_MODAL_OPEN: "CREATE_USER_ROLE_MODAL_OPEN",
    UPDATE_USER_MODAL_OPEN: "UPDATE_USER_MODAL_OPEN",
    UPDATE_USER_ROLE_MODAL_OPEN: "UPDATE_USER_ROLE_MODAL_OPEN",
    CREATE_DEPARTMENT_MODAL_OPEN: "CREATE_DEPARTMENT_MODAL_OPEN",
    UPDATE_DEPARTMENT_MODAL_OPEN: "UPDATE_DEPARTMENT_MODAL_OPEN",
    UPDATE_LOCALITY_NOTE_MODAL_OPEN: "UPDATE_LOCALITY_NOTE_MODAL_OPEN",
    UPDATE_LOCALITY_GROUP_NOTE_MODAL_OPEN: "UPDATE_LOCALITY_GROUP_NOTE_MODAL_OPEN",
    CREATE_LOCALITY_NOTE_MODAL_OPEN: "CREATE_LOCALITY_NOTE_MODAL_OPEN",
    CREATE_LOCALITY_GROUP_NOTE_MODAL_OPEN: "CREATE_LOCALITY_GROUP_NOTE_MODAL_OPEN",
    CREATE_TARIFF_NOTE_MODAL_OPEN: "CREATE_TARIFF_NOTE_MODAL_OPEN",
    MAIN_USER_CREATED: "MAIN_USER_CREATED",
    TARIFF_SNAPSHOT_MODAL_OPEN: "TARIFF_SNAPSHOT_MODAL_OPEN",
    LOCALITY_SNAPSHOT_MODAL_OPEN: "LOCALITY_SNAPSHOT_MODAL_OPEN",
    UPDATE_TARIFF_NOTE_MODAL_OPEN: "UPDATE_TARIFF_NOTE_MODAL_OPEN",
    TARIFF_NOTE_DELETED: "TARIFF_NOTE_DELETED",
    UPDATE_OPPONENT_MODAL_OPEN: "UPDATE_OPPONENT_MODAL_OPEN",
    UPDATE_MESSAGE_MODAL_OPEN: "UPDATE_MESSAGE_MODAL_OPEN",
    DOM_CHANGE: "DOM_CHANGE",
    TV_BUNDLE_CHANNELS_MODAL_OPEN: "TV_BUNDLE_CHANNELS_MODAL_OPEN",
    SHOW_LOCALITIES_MODAL: "SHOW_LOCALITIES_MODAL",
    SHOW_PRODUCT_MODAL: "SHOW_PRODUCT_MODAL",
    SHOW_SEND_SMS_MESSAGE_MODAL: "SHOW_SEND_SMS_MESSAGE_MODAL",
    CREATE_SMS_SAMPLE_MODAL_OPEN: "CREATE_SMS_SAMPLE_MODAL_OPEN",
    UPDATE_SMS_SAMPLE_MODAL_OPEN: "UPDATE_SMS_SAMPLE_MODAL_OPEN",
    SHOW_LOCALITIES_PICKER_MODAL: "SHOW_LOCALITIES_PICKER_MODAL",
    LOCALITIES_PICKER_MODAL_SELECTED: "LOCALITIES_PICKER_MODAL_SELECTED",
    SHOW_SCRIPT_MODAL: "SHOW_SCRIPT_MODAL",
    SHOW_SEND_NOTIFIER_MESSAGE_MODAL: "SHOW_SEND_NOTIFIER_MESSAGE_MODAL",
    SHOW_NOTE_MESSAGE_MODAL: "SHOW_NOTE_MESSAGE_MODAL",
    DEPARTMENT_CURRENT_TAB_CHANGED: "DEPARTMENT_CURRENT_TAB_CHANGED",
    FB_REPLY_TO_COMMENT_MODAL_OPEN: "FB_REPLY_TO_COMMENT_MODAL_OPEN",
    FB_POST_COMMENTS_MODAL_OPEN: "FB_POST_COMMENTS_MODAL_OPEN",
    IG_POST_PERMALINK_MODAL_OPEN: "IG_POST_PERMALINK_MODAL_OPEN",
    FB_PARENT_COMMENT_MODAL_OPEN: "FB_PARENT_COMMENT_MODAL_OPEN",
    FB_UPDATE_COUNT_UNREAD_COMMENTS: "FB_UPDATE_COUNT_UNREAD_COMMENTS",
    CLOSE_ALL_MODALS: "CLOSE_ALL_MODALS"
}
