<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ bundleName }}</h5>
                    <button @click="visible = false" type="button" class="close" data-dismiss="modal"
                            :aria-label="$t('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body position-relative" style="min-height: 90vh;">
                    <div v-if="tv_bundle === undefined" class="centered">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">{{ $t('Загрузка...') }}</span>
                        </div>
                    </div>
                    <div v-else-if="tv_bundle === false" class="alert alert-danger" role="alert">
                        {{ $t('Ошибка!') }}
                    </div>
                    <div v-else>
                        <div v-for="(group, groupName) in bundleChannelsGrouped" :key="groupName">
                            <h5 class="font-weight-bold mb-3">{{ groupName }} - {{ group.length }}</h5>

                            <div class="card bg-light p-2 mb-4">
                                <div class="d-flex flex-wrap justify-content-between">
                                    <div v-for="channel in group" :key="channel.id" class="channel-item">
                                        <img :src="channel.logoUrl" :alt="channel.name">
                                        <span>{{ channel.name }}</span>
                                    </div>
                                    <div v-for="item of 10" :key="item" class="channel-item__fix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {groupBy} from "lodash";
import {EventBus, Events} from "@/EventBus";
import TvBundleService from "@/services/TvBundleService";
import AbstractModal from "@/modals/AbstractModal.vue";

const TvBundle = new TvBundleService();

export default {
    name: "TvChannelsModal",
    extends: AbstractModal,
    data() {
        return {
            loading: false,
            tv_bundle: undefined
        }
    },
    methods: {
        async loadBundle(bundle_id) {
            const {code, body} = await TvBundle.get(bundle_id);
            return code === 200 ? body : false;
        },
        async onShowModal(bundle_id) {
            this.visible = true;
            this.loading = true;
            const tv_bundle = await this.loadBundle(bundle_id);
            this.loading = false;
            this.tv_bundle = tv_bundle;
        },
    },
    computed: {
        bundleName() {
            return this.tv_bundle?.name;
        },
        bundleChannels() {
            return this.tv_bundle?.channels || [];
        },
        bundleChannelsGrouped() {
            return groupBy(this.bundleChannels, "genre_name");
        }
    },
    mounted() {
        EventBus.$on(Events.TV_BUNDLE_CHANNELS_MODAL_OPEN, this.onShowModal);
    },
    beforeDestroy() {
        EventBus.$off(Events.TV_BUNDLE_CHANNELS_MODAL_OPEN, this.onShowModal);
    },
}
</script>

<style lang="less" scoped>

.channel-item {
    width: 80px;
    height: 100px;
    text-align: center;
    margin: 1rem;
    line-height: 1;

    img {
        width: 60px;
        height: 60px;
        object-fit: contain;
        border-radius: 1rem;
        border: 1px solid #ccc;
        display: block;
        margin: 0 auto .5rem auto;
        background: #fff;
    }

    span {
        font-size: .8rem;
        color: #606060;
    }

    &__fix {
        width: 80px;
        height: 1px;
        margin: 1rem;
    }
}

</style>
