<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ $t('Получение ссылки') }}
                    </h5>
                    <button @click="visible = false" type="button" class="close" data-dismiss="modal"
                            :aria-label="$t('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div v-if="isLoading" class="d-flex justify-content-center align-items-center">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div v-else>
                        <a :href="permalink" @click="closeWithDelay" target="_blank" class="btn btn-primary btn-block">
                            {{ $t("Перейти") }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {EventBus, Events} from "@/EventBus";
import AbstractModal from "@/modals/AbstractModal.vue";
import InstagramCommentsService from "@/services/InstagramCommentsService";

const InstagramComments = new InstagramCommentsService();

export default {
    name: "InstagramPostPermalinkModal",
    extends: AbstractModal,
    data() {
        return {
            comment: null,
            isLoading: false,
            permalink: ""
        }
    },
    methods: {
        async getPostPermalink() {
            this.isLoading = true;
            const {body} = await InstagramComments.getPostPermalink(this.comment.id);
            this.isLoading = false;

            this.permalink = body?.permalink || "https://www.youtube.com/watch?v=dQw4w9WgXcQ";
        },
        onShowModal(comment) {
            this.visible = true;
            this.comment = comment;
            this.getPostPermalink();
        },
        closeWithDelay() {
            setTimeout(this.closeModal, 1000);
        }
    },
    mounted() {
        EventBus.$on(Events.IG_POST_PERMALINK_MODAL_OPEN, this.onShowModal);
    },
    beforeDestroy() {
        EventBus.$off(Events.IG_POST_PERMALINK_MODAL_OPEN, this.onShowModal);
    }
}
</script>

<style lang="less" scoped>

</style>
