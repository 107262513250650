<template>
    <div>
        <Navbar>
            <div class="row">
                <div class="col-12">
                    <h4 class="text-light mb-0">{{ $t('Facebook комментарии') }}</h4>
                </div>
            </div>
        </Navbar>
        <div class="container-fluid pt-4">
            <FacebookCommentsPagesTabs :pages="pages"
                                       :currentPageId="currentPageId"
                                       @change="currentPageId = $event"/>
            <FullHeightBlock class="custom-scroll">
                <div class="row">
                    <div class="col-12">
                        <vue-good-table
                            :key="currentPageId"
                            :columns="columns"
                            :rows="comments"
                            mode="remote"
                            :search-options="{
                                enabled: true,
                                placeholder: $t('Поиск по комментарию или автору')
                            }"
                            @on-search="onSearch"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            @on-sort-change="onSortChange"
                            :totalRows="total"
                            :isLoading.sync="isLoading"
                            :pagination-options="{
                            enabled: true,
                            perPageDropdown: [20, 50, 100],
                            dropdownAllowAll: false,
                            nextLabel: $t('туда'),
                            prevLabel: $t('сюда'),
                            ofLabel: $t('из'),
                            rowsPerPageLabel: $t('Записей на страницу'),
                        }">
                            <template slot="table-row" slot-scope="props">
                                <CommentsTablePageItem v-if="props.column?.field === 'entry_id'"
                                                       :pages="pages"
                                                       :page_id="props.row.entry_id"/>
                                <div v-else-if="props.column?.field === 'object'" class="text-center">
                                    <a v-if="props.row.object === 'page'"
                                       target="_blank"
                                       :href="props.row?.post_permalink_url || 'https://www.youtube.com/watch?v=dQw4w9WgXcQ'">
                                        <i class="fab fa-lg fa-facebook-square text-primary"></i>
                                    </a>
                                    <a v-if="props.row.object === 'instagram'"
                                       @click.prevent="toInstagramPost(props.row)" href="#">
                                        <i class="fab fa-lg fa-instagram text-warning"></i>
                                    </a>
                                </div>
                                <div v-else-if="props.column?.field === 'from_id'">
                                    <i class="text-muted">{{ props.row.message | stringLimit }}</i>
                                    <img v-if="props.row.photo" :src="props.row.photo" alt="photo"
                                         style="max-height: 30px;">
                                </div>
                                <div v-else-if="props.column?.field === 'id'" class="text-center">
                                    <i v-if="props.row.has_owner_reply"
                                       data-toggle="tooltip"
                                       :title="$t('Администратор страницы ответил на этот комментарий')"
                                       class="fas fa-comments fa-lg text-success"></i>
                                </div>
                                <div v-else-if="props.column?.field === 'created_time'" class="text-nowrap">
                                    <div>{{ dateToString(props.row.created_time) }}</div>
                                    <small class="text-muted">{{ props.row.created_time | formatDate }}</small>
                                </div>
                                <div v-else-if="props.column?.field === 'comment_id'">
                                    <button @click="makeReply(props.row)" class="btn btn-primary btn-block btn-sm">
                                        {{ $t("Открыть") }}
                                    </button>
                                </div>
                                <div v-else>
                                    {{ props.formattedRow[props.column.field] }}
                                </div>
                            </template>
                            <div slot="emptystate" class="text-center py-5">
                                <div class="display-4 text-black-50">{{ $t('Тут пока ничего нет') }}</div>
                            </div>
                        </vue-good-table>
                    </div>
                </div>
            </FullHeightBlock>
            <FacebookReplyToCommentModal @replied="loadComments"/>
            <FacebookPostCommentsModal/>
            <FacebookParentCommentModal/>
            <InstagramPostPermalinkModal />
        </div>
    </div>
</template>

<script>

import Navbar from "@/components/Navbar";
import 'vue-good-table/dist/vue-good-table.css'
import {VueGoodTable} from 'vue-good-table';
import FullHeightBlock from "@/components/FullHeightBlock.vue";
import FacebookIntegrationService from "@/services/FacebookIntegrationService";
import CommentsTablePageItem from "@/components/facebook_commentns/CommentsTablePageItem.vue";
import moment from "moment-mini"
import FacebookReplyToCommentModal from "@/modals/FacebookReplyToCommentModal.vue";
import FacebookCommentsService from "@/services/FacebookCommentsService";
import FacebookCommentsPagesTabs from "@/components/facebook_commentns/FacebookCommentsPagesTabs.vue";
import FacebookPostCommentsModal from "@/modals/FacebookPostCommentsModal.vue";
import FacebookParentCommentModal from "@/modals/FacebookParentCommentModal.vue";
import {debounce, truncate} from "lodash";
import TimeAgo from "javascript-time-ago";
import ru from 'javascript-time-ago/locale/ru'
import uk from 'javascript-time-ago/locale/uk'
import en from 'javascript-time-ago/locale/en'
import bg from 'javascript-time-ago/locale/bg'
import {EventBus, Events} from "@/EventBus";
import InstagramPostPermalinkModal from "@/modals/InstagramPostPermalinkModal.vue";

const FacebookIntegration = new FacebookIntegrationService();
const FacebookComments = new FacebookCommentsService();

export default {
    name: 'FacebookComments',
    components: {
        InstagramPostPermalinkModal,
        FacebookParentCommentModal,
        FacebookPostCommentsModal,
        FacebookCommentsPagesTabs,
        FacebookReplyToCommentModal,
        CommentsTablePageItem,
        FullHeightBlock,
        Navbar,
        VueGoodTable,
    },
    filters: {
        formatDate(date) {
            return moment(date).format("YYYY-MM-DD HH:mm:ss");
        },
        stringLimit(str) {
            if (!str) return str;
            return truncate(str, {length: 50})
        }
    },
    data() {
        return {
            comments: [],
            pages: [],
            total: 0,
            limit: 20,
            offset: 0,
            sort_by: 'created_time',
            sort_order: 'desc',
            isLoading: false,
            currentPageId: null,
            search: null
        }
    },
    methods: {
        toInstagramPost(comment) {
            EventBus.$emit(Events.IG_POST_PERMALINK_MODAL_OPEN, comment);
        },
        async makeReply(comment) {
            if (!comment.read_at) {
                await FacebookComments.markCommentAsRead(comment.id);

                this.comments = this.comments.map(item => {
                    if (item.id === comment.id) {
                        item.read_at = moment().toISOString();
                    }

                    return item;
                });
            }

            EventBus.$emit(Events.FB_UPDATE_COUNT_UNREAD_COMMENTS);
            EventBus.$emit(Events.FB_REPLY_TO_COMMENT_MODAL_OPEN, comment);
        },
        dateToString(date) {
            const timeAgo = new TimeAgo(this.$i18n.locale)
            return timeAgo.format(new Date(date));
        },
        async loadComments() {
            this.isLoading = true;
            const {code, body} = await FacebookComments.fetchComments({
                limit: this.limit,
                offset: this.offset,
                sort_by: this.sort_by,
                sort_order: this.sort_order,
                page_id: this.currentPageId,
                hide_own_comments: 1,
                search: this.search,
            })
            this.isLoading = false;

            if (code === 200) {
                this.comments = body.data.map(comment => {
                    comment.is_own_comment = this.isOwnComment(comment);
                    comment.has_owner_reply = this.hasOwnerReply(comment);
                    return comment;
                });
                this.total = body.total
            } else {
                this.$toast.error(body?.message || this.$t("Не удалось загрузить комментарии"))
            }
        },
        async loadPages() {
            this.isLoading = true;
            const {code, body} = await FacebookIntegration.getUserPages();
            this.isLoading = false;

            if (code === 200) {
                this.pages = body;
            } else {
                this.$toast.error(body?.message || this.$t("Не удалось загрузить информацию о страницах"))
            }
        },
        async onSortChange(e) {
            if (e[0].type !== "none") {
                this.sort_by = e[0].field;
                this.sort_order = e[0].type;
            } else { // default
                this.sort_by = 'created_time';
                this.sort_order = 'desc';
            }

            await this.loadComments();
        },
        async onPageChange(e) {
            this.offset = (e.currentPage * this.limit) - this.limit;
            await this.loadComments();
        },
        async onPerPageChange(e) {
            this.limit = e.currentPerPage;
            await this.loadComments();
        },
        onSearch: debounce(async function ({searchTerm}) {
            this.search = searchTerm?.trim();

            if (!this.isLoading) {
                this.limit = 20;
                this.offset = 0;
                await this.loadComments();
            }
        }, 600),
        isOwnComment(comment) {
            return comment.entry_id === comment.from_id;
        },
        hasOwnerReply(comment) {
            if (comment.is_own_comment) {
                return false;
            }

            return !!comment.children.find(comment => this.isOwnComment(comment));
        },
        tdClassFunc(row) {
            return !row.read_at ? 'unread-comment' : '';
        },
        reset() {
            this.limit = 20;
            this.offset = 0;
            this.sort_by = 'created_at';
            this.sort_order = 'desc';
            this.search = null;
        }
    },
    computed: {
        columns() {
            const columns = [
                {
                    label: this.$tc('Пользователь'),
                    field: 'from_name',
                    tdClass: this.tdClassFunc,
                    sortable: false
                },
                {
                    label: this.$tc('Комментарий'),
                    field: 'from_id',
                    tdClass: this.tdClassFunc,
                    sortable: false
                },
                {
                    label: this.$tc('Есть ответ'),
                    field: 'id',
                    tdClass: this.tdClassFunc,
                    sortable: false
                },
                {
                    label: this.$tc('Дата'),
                    field: 'created_time',
                    tdClass: this.tdClassFunc,
                },
                {
                    label: this.$tc('Действия'),
                    field: 'comment_id',
                    tdClass: this.tdClassFunc,
                    sortable: false
                },
            ];

            if (null === this.currentPageId) {

                columns.unshift({
                    label: this.$tc('Сеть'),
                    field: 'object',
                    tdClass: this.tdClassFunc,
                    sortable: false
                });

                columns.unshift({
                    label: this.$tc('Страница'),
                    field: 'entry_id',
                    tdClass: this.tdClassFunc,
                    sortable: false
                });
            }

            return columns;
        },
    },
    watch: {
        currentPageId() {
            this.$nextTick(() => {
                this.reset();
                this.loadComments();
            })
        }
    },
    created() {
        TimeAgo.addLocale(uk);
        TimeAgo.addLocale(ru);
        TimeAgo.addLocale(en);
        TimeAgo.addLocale(bg);
    },
    mounted() {
        document.title = this.$tc("Facebook комментарии");
        this.$preloader.show();
        Promise.all([
            this.loadComments(),
            this.loadPages()
        ]).catch(() => {
            this.$toast.error(this.$t("Не удалось загрузить данные"));
        }).finally(() => {
            this.$preloader.hide();
        })
    }
}
</script>

<style lang="less">
.unread-comment {
    background: #fffbcf;
}
</style>
