<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ $t('Родительский комментарий') }}
                    </h5>
                    <button @click="visible = false" type="button" class="close" data-dismiss="modal"
                            :aria-label="$t('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div v-if="isLoading" class="d-flex justify-content-center align-items-center py-5">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div v-else>
                        <div class="font-italic text-muted mb-2">
                            <strong class="pr-2">{{ parentComment?.from_name }}</strong> &#x2022;
                            <span class="px-2">{{ $t('написал(а)') }}</span> &#x2022;
                            <small class="px-2">{{ parentComment?.created_time | formatDate }}</small> &#x2022;
                            <a class="px-2" v-if="parentComment?.post_permalink_url" :href="parentComment?.post_permalink_url"
                               target="_blank">
                                {{ $t("Перейти к посту") }}
                                <i class="fas fa-external-link-alt"></i>
                            </a>
                        </div>
                        <div class="comment-wrapper mb-4 py-3">
                            {{ parentComment?.message }}
                            <img v-if="parentComment?.photo" :src="parentComment?.photo" style="max-width: 100px; max-height: 100px;"
                                 alt="img">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {EventBus, Events} from "@/EventBus";
import moment from "moment-mini";
import FacebookCommentsService from "@/services/FacebookCommentsService";
import AbstractModal from "@/modals/AbstractModal.vue";

const FacebookComments = new FacebookCommentsService();

export default {
    name: "FacebookParentCommentModal",
    extends: AbstractModal,
    data() {
        return {
            comment: null,
            parentComment: null,
            isLoading: false,
        }
    },
    filters: {
        formatDate(date) {
            return moment(date).format("YYYY-MM-DD HH:mm:ss");
        },
    },
    methods: {
        async loadParentComment() {
            this.isLoading = true;
            const {code, body} = await FacebookComments.getParentComment(this.comment.id);
            this.isLoading = false;

            if (code === 200) {
                this.parentComment = body;
            }
        },
        onShowModal(comment) {
            this.visible = true;
            this.comment = comment;
            this.loadParentComment()
        },
    },
    mounted() {
        EventBus.$on(Events.FB_PARENT_COMMENT_MODAL_OPEN, this.onShowModal);
    },
    beforeDestroy() {
        EventBus.$off(Events.FB_PARENT_COMMENT_MODAL_OPEN, this.onShowModal);
    }
}
</script>

<style lang="less" scoped>
.comment-wrapper {
    background: #def1ff;
    border: 1px solid #9cdaff;
    padding: 10px;
    border-radius: 10px;
}
</style>
