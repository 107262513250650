<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ $t('Добавление нового пользователя') }}
                    </h5>
                    <button @click="visible = false" type="button" class="close" data-dismiss="modal"
                            :aria-label="$t('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label class="font-weight-bold">{{ $t('Имя') }} <span class="text-danger">*</span></label>
                        <input v-model="name" type="text" class="form-control">
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold">{{ $t('Email') }} <span class="text-danger">*</span></label>
                        <input :readonly="id" v-model="email" type="email" class="form-control">
                    </div>
                    <div class="form-group">
                        <CreateUserModalPhones @change="phones => this.phones = phones">
                        </CreateUserModalPhones>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold">{{ $t('Роль') }} <span class="text-danger">*</span></label>
                        <select :disabled="id === $store.state.user.id" v-model="role_id" class="form-control mb-3">
                            <option v-for="(role, index) in $store.state.rolesList" :key="index" :value="role.id">
                                {{ role.name }}
                            </option>
                        </select>
                        <small v-if="role_id == 1" class="alert alert-danger d-block">
                            {{
                                $t('Точно? Новый администратор тоже сможет добавлять и удалять пользователей, и даже сможет удалить этот аккаунт!')
                            }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold">{{ $t('Департамент') }} <span
                            class="text-danger">*</span></label>
                        <select v-model="department_id" class="form-control mb-3">
                            <option :value="null">
                                {{ $t('Не указано') }}
                            </option>
                            <option v-for="(item, index) in $store.state.departments" :key="index" :value="item.id">
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold">{{ $t('Пароль') }} <span class="text-danger">*</span></label>
                        <div class="input-group mb-3">
                            <input v-model="password" type="text" class="form-control">
                            <div class="input-group-append">
                                <button @click="password = generatePassword()" class="btn btn-primary" type="button"><i
                                    class="fas fa-random"></i></button>
                            </div>
                        </div>
                    </div>
                    <div v-if="!id || (id && password.length > 0)" class="form-check">
                        <input v-model="send_credentials" :true-value="1" :false-value="0" type="checkbox"
                               class="form-check-input">
                        <label class="form-check-label">{{ $t('Отправить данные для авторизации на Email') }}</label>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="create" :disabled="processing" class="btn btn-block btn-primary">
                        <div v-if="processing" class="spinner-border spinner-border-sm text-white" role="status">
                            <span class="sr-only">{{ $t('Загрузка...') }}</span>
                        </div>
                        <span v-else>{{ $t('Сохранить') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {EventBus, Events} from "@/EventBus";
import UserService from "@/services/UserService";
import CreateUserModalPhones from "@/components/CreateUserModalPhones";
import UserPhoneService from "@/services/UserPhoneService";
import departments from "@/views/Departments.vue";
import AbstractModal from "@/modals/AbstractModal.vue";

const User = new UserService();
const UserPhone = new UserPhoneService()

export default {
    name: "CreateUserModal",
    extends: AbstractModal,
    computed: {
        departments() {
            return departments
        }
    },
    components: {
        CreateUserModalPhones
    },
    data() {
        return {
            id: null,
            processing: false,
            name: "",
            email: "",
            role_id: 1,
            department_id: null,
            password: "",
            phones: [],
            send_credentials: 0
        }
    },
    methods: {
        async createPhone(item) {
            this.phones_preloader = true;

            const {code, body} = await UserPhone.create({
                user_id: this.id,
                ...item
            });

            if (code === 201) {
                this.$toast.success(this.$t('Телефон добавлен'));
                this.$emit("changePhone");
                const {body} = await UserPhone.get(this.id);
                this.phones = body;
            } else {
                this.$toast.error(body.message);
            }

            this.phones_preloader = false;
        },
        async create() {
            this.processing = true;
            const {code, body} = await User.create(this.$data);
            this.processing = false;

            if (code === 201) {

                for (let i = 0; i < this.phones.length; i++) {
                    await UserPhone.create({
                        user_id: body.id,
                        ...this.phones[i]
                    })
                }

                this.$toast.success(this.$t('Пользователь добавлен'));
                this.$emit("created", body);
                this.visible = false;
            } else {
                this.$toast.error(body.message)
            }
        },
        generatePassword() {
            let length = 16,
                charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+-",
                retVal = "";
            for (let i = 0, n = charset.length; i < length; ++i) {
                retVal += charset.charAt(Math.floor(Math.random() * n));
            }
            return retVal;
        },
    },
    mounted() {
        EventBus.$on(Events.CREATE_USER_MODAL_OPEN, this.onShowModal);
    },
    beforeDestroy() {
        EventBus.$off(Events.CREATE_USER_MODAL_OPEN, this.onShowModal);
    }
}
</script>

<style scoped>

</style>
