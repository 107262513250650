<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ $t('Редактирование конкурента') }}
                    </h5>
                    <button @click="visible = false" type="button" class="close" data-dismiss="modal"
                            :aria-label="$t('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group mb-4">
                        <input v-model="title" class="form-control" type="text">
                    </div>
                    <div class="form-group">
                        <textarea class="form-control" v-model="text" rows="10"></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="update" :disabled="processing" class="btn btn-block btn-primary">
                        <div v-if="processing" class="spinner-border spinner-border-sm text-white" role="status">
                            <span class="sr-only">{{ $t('Загрузка...') }}</span>
                        </div>
                        <span v-else>{{ $t('Сохранить') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {EventBus, Events} from "@/EventBus";
import OpponentService from "@/services/OpponentService";
import AbstractModal from "@/modals/AbstractModal.vue";

const Opponent = new OpponentService();

export default {
    name: "UpdateOpponentModal",
    extends: AbstractModal,
    data() {
        return {
            id: null,
            text: false,
            title: false,
            processing: false,
        }
    },
    methods: {
        async update() {
            this.processing = true;
            const {code, body} = await Opponent.update(this.$data);
            this.processing = false;

            if (code === 200) {
                this.$toast.success(this.$t('Конкурент обновлен'));
                this.$emit("updated", body);
                this.visible = false;
            } else {
                this.$toast.error(body.message)
            }
        },
        onShowModal({id, title, text}) {
            this.visible = true;
            this.id = id;
            this.title = title;
            this.text = text;
        },
    },
    mounted() {
        EventBus.$on(Events.UPDATE_OPPONENT_MODAL_OPEN, this.onShowModal);
    },
    beforeDestroy() {
        EventBus.$off(Events.UPDATE_OPPONENT_MODAL_OPEN, this.onShowModal);
    }
}
</script>

<style scoped>

</style>
