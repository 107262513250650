import i18n from "../i18n";
import FacebookIntegrationService from "@/services/FacebookIntegrationService";

export default class FacebookManager {

    constructor() {
        this.service = new FacebookIntegrationService();
    }

    login() {
        return new Promise((resolve, reject) => {
            window.FB.login(r => {
                if (r.authResponse) {
                    resolve(r.authResponse.accessToken);
                } else {
                    reject(new Error(i18n.tc('Не удалось завершить аутентификацию')));
                }
            }, {
                scope: [
                    'pages_show_list',
                    'pages_manage_engagement',
                    'pages_read_engagement',
                    'pages_manage_metadata',
                    'instagram_manage_comments',
                    'instagram_basic'
                ].join(",")
            });
        });
    }

    getUserExchangeToken(token) {
        return new Promise((resolve, reject) => {
            this.service.getUserExchangeToken(token)
                .then(r => {
                    if (r.code === 200) {
                        resolve(r.body.access_token);
                    } else {
                        reject(new Error(i18n.tc('Не удалось получить exchange-token пользователя')));
                    }
                });
        });
    }

    getSelectedPages(userExchangeToken) {
        return new Promise((resolve, reject) => {
            window.FB.api(`/me/accounts?fields=name,page_id,access_token,profile_picture_url,category,instagram_business_account{id,username,profile_picture_url}`, {access_token: userExchangeToken}, r => {
                if (r.error) {
                    reject(new Error(i18n.tc("Ошибка получения страниц")))
                } else {
                    const pages = r.data.flatMap(page => {
                        const result = [
                            {
                                page_id: page.id,
                                type: "facebook",
                                name: page.name,
                                picture_url: page.profile_picture_url,
                                parent_id: null,
                                category: page.category,
                                access_token: page.access_token,
                            }
                        ];

                        if (page.instagram_business_account) {
                            result.push({
                                page_id: page.instagram_business_account.id,
                                name: page.instagram_business_account.username,
                                type: "instagram",
                                parent_id: page.id,
                                picture_url: page.instagram_business_account.profile_picture_url,
                                category: page.category,
                                access_token: page.access_token,
                            });
                        }

                        return result;
                    });

                    resolve(pages);
                }
            });
        })
    }

    connectPageToApp(page) {
        return new Promise((resolve, reject) => {
            window.FB.api(`/${page.page_id}/subscribed_apps`, 'POST', {
                    access_token: page.access_token,
                    subscribed_fields: ['feed']
                }, r => {
                    if (r.error) {
                        reject(new Error(`Ошибка подписки страницы ${page.page_id}`));
                    } else {
                        resolve(true);
                    }
                }
            );
        });
    }
}
